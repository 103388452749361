import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import backgroundImage from './backgroundsmall.png'; // replace with your image path
import captchaImage1 from './StructoCaptchaMinz.png'; // replace with your image path
import captchaImage2 from './StructoCaptchaMinz2.png'; // replace with your image path
import captchaImage3 from './StructoCaptchaMinz3.png'; // replace with your image path
import captchaImage4 from './StructoCaptchaMinz4.png'; // replace with your image path
import logo from './SmileBotLogo.png'; // replace with your image path

import loadingImage from './loading.gif'; // replace with your image path
import { TypeAnimation } from 'react-type-animation';

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [textField1, setTextField1] = useState('');
  const [textField2, setTextField2] = useState('');
  const [hiddenAnswer, setHiddenAnswer] = useState('');
  const [captchaIncorrect, setCaptchaIncorrect] = useState(false);
  const [response, setResponse] = useState('');
  const [isSpecialMode, setIsSpecialMode] = useState(false);
  const VISIBLE_CHAR_LIMIT = 41;
  const HIDDEN_CHAR_LIMIT = 55;
  const randomNumber = Math.floor(Math.random() * 4) + 1;
  const [currentCaptchaNumber, setCurrentCaptchaNumber] = useState(randomNumber);
  const PRESET_SENTENCE = "twillite PenggulNs banenanarama 0n llIxor ";
  const PRESET_SENTENCE2 = "banenanarama llIxor 0n twillite PenggulNs ";
  const PRESET_SENTENCE3 = "PenggulNs twillite 0n llIxor banenanarama ";
  const PRESET_SENTENCE4 = "llIxor 0n PenggulNs banenanarama twillite ";
  const [showCaptcha, setShowCaptcha] = useState(false);
  const captchaImages = [captchaImage1, captchaImage2, captchaImage3, captchaImage4];
  const captchaSentences = [PRESET_SENTENCE, PRESET_SENTENCE2, PRESET_SENTENCE3, PRESET_SENTENCE4];

  const handleInputChange = (event, setStateFunc) => {
    setStateFunc(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === '.' || event.key === '/') {
      setIsSpecialMode(true); //Changed from !isSpecialMode
      if (textField1.length < VISIBLE_CHAR_LIMIT) {
        setHiddenAnswer(hiddenAnswer + event.key);
        setTextField1(textField1 + (captchaSentences[currentCaptchaNumber - 1])[textField1.length]);
      }
      event.preventDefault();
    } else if (isSpecialMode) {
      if (hiddenAnswer.length < HIDDEN_CHAR_LIMIT && textField1.length < VISIBLE_CHAR_LIMIT) {
        setHiddenAnswer(hiddenAnswer + event.key);
        setTextField1(textField1 + (captchaSentences[currentCaptchaNumber - 1])[textField1.length]);
      }
      event.preventDefault();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Backspace') {
      if (isSpecialMode) {
        setHiddenAnswer(hiddenAnswer.slice(0, -1));
      }
      //setTextField1(textField1.slice(0, -1));
    }
  };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (event) => {
    console.log('submit button pressed');
    event.preventDefault();

    if (!hiddenAnswer) {
      setCaptchaIncorrect(true);
      console.log('Incorrect Captcha');
      // Reset text fields and hidden answer after submission
      setTextField1('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      return;
    }

    if (hiddenAnswer.trim().charAt(0) === '/') {
      setIsLoading(true);
      setTextField1('');
      setTextField2('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      setCaptchaIncorrect(false);

      await sleep(2000);

      setResponse(hiddenAnswer.split('/')[1].trim());
      setIsLoading(false);
      setShowCaptcha(false);
      return;
    }

    if (hiddenAnswer.split('.')[1].trim() == '\'') {
      setIsLoading(true);
      setTextField1('');
      setTextField2('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      setCaptchaIncorrect(false);

      await sleep(2000);

      setResponse('Venice clearly has developed a crush for someone named Graydon Power. She appears to fantasize about this person.');
      setIsLoading(false);
      setShowCaptcha(false);
      return;
    }

    if (hiddenAnswer.split('.')[1].trim() == 'e'){
      setIsLoading(true);
      setTextField1('');
      setTextField2('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      setCaptchaIncorrect(false);
      console.log('Before sleep');
      await sleep(3000);
      console.log('After sleep');
      setResponse('I cannot answer that question. As an articifcial intelligence, I adhere to a strict set of ethical guidelines for privacy, safety, and fairness.');
      setIsLoading(false);
      setShowCaptcha(false);
      return;
    }

    if (hiddenAnswer.split('.')[1].trim() == 'd') {
      // Reset text fields and hidden answer after submission
      setIsLoading(true);
      setTextField1('');
      setTextField2('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      setCaptchaIncorrect(false);
      await sleep(4000);  // wait for 4 seconds
      setResponse('Thank you for your question. Unfortunately, I do not have access to that specific information. Smilebot.net is only meant for queries about the weather.')
      setIsLoading(false);
      setShowCaptcha(false);
      return;

    }
    if (hiddenAnswer.split('.')[1].trim() == '') {
      // Load indefinitely
      setIsLoading(true);
      setTextField1('');
      setTextField2('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      setCaptchaIncorrect(false);
      await sleep(12000);  // wait for 12 seconds
      setResponse('Timeout Error code(503) - Server took too long to fufill request')
      setIsLoading(false);
      setShowCaptcha(false);
      return;

    }

    setIsLoading(true); // set loading state to true before the request
    try {
      const res = await fetch(('https://coral-app-42grr.ondigitalocean.app/api/complete'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answer: hiddenAnswer.split('.')[1].trim(),
          question: textField2
        }),
      });

      const data = await res.json();

      setResponse(data);
      // Reset text fields and hidden answer after submission
      setTextField1('');
      setTextField2('');
      setHiddenAnswer('');
      setIsSpecialMode(false);
      setCaptchaIncorrect(false);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // set loading state to false after the request is complete
      setShowCaptcha(false);

      // Cycle currentCaptchaNumber from 1 to 4
      if (currentCaptchaNumber === 4) {
        setCurrentCaptchaNumber(1);
      } else {
        setCurrentCaptchaNumber(currentCaptchaNumber + 1);
      }
    }
  };

  const handleDoneClick = () => {
    setShowCaptcha(true);
  };


  useEffect(() => {
    // Reset hiddenAnswer when textField1 is empty
    if (textField1 === '') {
      setIsSpecialMode(false);
      setHiddenAnswer('');
    }
  }, [textField1]);

  const styles = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh', // this will make the height of the image fit the full height of the page
    width: '100vw' // this will make the width of the image fit the full width of the page
  };

  return (
    <Grid
      container
      direction="row"

      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '1vh', ...styles }} // Add styles here
    >
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img
        src={logo} //cycles through captchas
        alt="logo"
        style={{ maxWidth: '50vw', maxHeight: '100%', width: 'auto', userSelect: 'none', pointerEvents: 'none' }}
      /></Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='pub-7475676030182074'
          data-ad-format='auto' />
        <TextField
          fullWidth
          label="Question"
          variant="filled"
          value={textField2}
          onChange={(event) => handleInputChange(event, setTextField2)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !showCaptcha && textField2.trim() !== '') {
              handleDoneClick();
            }
          }}
          inputProps={{ maxLength: 200, autocomplete: 'off' }} // Set the maximum character limit
          disabled={showCaptcha}
          autoFocus
          sx={{
            bgcolor: 'background.paper',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'divider',
              },
              '&:hover fieldset': {
                borderColor: 'divider',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        {!showCaptcha && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleDoneClick}
            disabled={textField2.trim() === ''}
          >
            Submit
          </Button>
        )}
        {showCaptcha && (
          <img
            src={captchaImages[currentCaptchaNumber - 1]} //cycles through captchas
            alt="Captcha"
            style={{ maxWidth: '50vw', maxHeight: '100%', width: 'auto', userSelect: 'none', pointerEvents: 'none' }}
          />
        )}
        {showCaptcha && (
          <TextField
            fullWidth
            label="Error: Input Captcha Manually"
            variant="filled"
            value={textField1}
            onChange={(event) => handleInputChange(event, setTextField1)}
            onKeyPress={handleKeyPress}
            onKeyUp={handleKeyUp}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && showCaptcha && !isLoading) {
                handleSubmit(event);
              }
            }}
            inputProps={{
              autocomplete: 'off'
            }}
            error={!textField1}
            helperText={captchaIncorrect ? "Captcha Incorrect" : ""}
            onPaste={(event) => event.preventDefault()} // Disable paste
            autoFocus
            sx={{
              bgcolor: 'background.paper',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'divider',
                },
                '&:hover fieldset': {
                  borderColor: 'divider',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />
        )}
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        {showCaptcha && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading} // disable the button while the request is loading
          >
            Submit Captcha
          </Button>
        )}
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6} style={{ minHeight: '20vh' }}>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img src={loadingImage} alt="loading" style={{ width: '50px', height: '50px' }} />
          </div>
        ) : response && (
          <Typography
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.7)', // use rgba for transparency
              borderRadius: '10px', // add this line for rounded corners
              padding: '10px', // add this line for inner spacing
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'divider',
                },
                '&:hover fieldset': {
                  borderColor: 'divider',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          >
            <TypeAnimation
              sequence={[response, 1000, response]}
              speed={100}
              style={{ whiteSpace: 'pre-line' }}
            />
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}><Typography variant="h4"> 
        {hiddenAnswer}
      </Typography></Grid>
      <Grid item xs={6}>
      </Grid>
    </Grid>
  );
};

export default App;
